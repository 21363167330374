import React, { useCallback, useEffect, useState } from "react"

import api from 'service';

export default function AdminPanel() {
  const [akrosLicenses, setAKROSLicenses] = useState(() => {

  });
  const getAKROSLicenses = useCallback(async () => {
    const { REACT_APP_AKROS_ENDPOINT, REACT_APP_AKROS_CLIENT_ID} = process.env;
    try {

      const params = { clientId: REACT_APP_AKROS_CLIENT_ID };

      await api.get(`${REACT_APP_AKROS_ENDPOINT}?clientId=${REACT_APP_AKROS_CLIENT_ID}`)
      .then(response => {
          console.log(`[ ${response} ]:: ${response}`);
      })
      .catch(error => {
          console.error('Error:', error);
      });
    } catch(e){
      console.error(e);
    }
  })


  useEffect(() => {
    getAKROSLicenses();
  }, [])

  return (
    <div>
      <span>ADMIN PANEL</span>
    </div>
  )
}
