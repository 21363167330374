const localStorageItems = [
  `${process.env.REACT_APP_TOKEN}`,
  `${process.env.REACT_APP_USER}`,
  `${process.env.REACT_APP_USER_STATS}`,
  `${process.env.REACT_APP_MATCH_STATS}`,
  `${process.env.REACT_APP_USER_MATCHES}`,
  `${process.env.REACT_APP_LEADERBOARD}`,
];

export default localStorageItems;
